import styled from 'styled-components';

export const DonationWrapper = styled.div`
  z-index: 100;
  margin-top: 20%;
  background-color: #fff;
  opacity: 0.6;
  border-radius: 15px;
  padding: 15px 30px 15px 30px;
  left: 0;
  right: 0;
  margin-left: 25%;
  margin-right: 25%;
  text-align: center;
  border: 4px solid rgba(255, 185, 0, 0.85);
  min-width: 2rem;
  @media (max-width: 600px) {
    margin-left: 5%;
    margin-right: 5%;
  }
`;
export const StyledButtonPosition = styled.div`
  max-width: 20rem;
  margin: auto !important;
  right: 0;
  text-align: center !important;
`;

export const Title = styled.h1`
  letter-spacing: 7px;
  color: ${({ theme }) => theme.colors.mediumRedViolet};
  font-waight: bold;
  margin-bottom: 1rem;
  text-shadow: 0.3px 0.2px 0.1px #000000;
`;

export const TitleImg = styled.h1`
  letter-spacing: 7px;
  margin-top: 1rem;
  text-align: center;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.mediumRedViolet};
`;

export const Subtitle = styled.h4`
  color: rgba(255, 185, 0, 0.85);
  font-waight: bold;
  margin-bottom: 2rem;
  text-shadow: 0.3px 0.2px 0.1px #000000;
`;

export const CenterImg = styled.div`
  display: inline-block !important;
  text-align: center;
`;

export const SubtitleSection1 = styled.h4`
  margin-bottom: 3rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.mediumRedViolet};
`;

export const PartnerWithUsBtn = styled.div`
  margin-top: 3rem;
  margin-bottom: 3rem;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  @media (max-width: 600px) {
    width: 90%;
  }
`;

export const ThankYou = styled.p`
  text-align: center;
  font-size: 16.92px;
`;

export const IconText = styled.p`
  text-align: center;
  font-size: 16.92px;
`;

export const Asterisk = styled.p`
  color: ${({ theme }) => theme.colors.mediumRedViolet};
  font-size: 2rem;
  text-align: center;
  margin-top: 5rem;
`;

export const IconBox = styled.div`
  display: flex;
  justif-content: center;
  padding: 0% 10%;
  margin-top: 3rem;
`;
