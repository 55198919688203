import styled from 'styled-components';
import Card from 'react-bootstrap/Card'
export const CloseBtnPosition = styled.div`
margin-left: auto;
margin-right: .8rem;
`;

export const StyledCard = styled(Card)`   
    border:none;
    border-radius:5px!important;
    box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
    margin-left: auto;
    margin-right: auto;   
    background: #fff!important;
    padding: 0;
    max-width: 35rem;
    //font-size: 16.8px;
    
}
`;
export const Subtitle = styled.h4`
    color: ${({ theme }) => theme.colors.mediumRedViolet};
    text-shadow: .3px .2px .1px #333333;
    text-align: center;
    line-height: 3rem;
    padding-bottom:2rem;
padding-top:0;
margin-top:0;
`;
export const Title = styled.h2`
    color: ${({ theme }) => theme.colors.mediumRedViolet};
    letter-spacing: 7px;
    text-align: center!important;        
    margin-bottom: 3rem;
`;
export const TestimonialTitle = styled.p`
font-size: 1rem;
`;
export const StyledCardBody = styled(Card.Body)`
height:100%;
`;

export const StyledCardImage = styled.div`
display: block;
margin-left: auto;
margin-right: auto;
max-width:25rem;
`;

export const Name = styled.h4`    
    text-align: center;
    font-weight: bold;
`;
export const StyledCardButton = styled.button`
    background: #fff !important;
    padding: 10px;
    height: 100%;
    border:none;
    border-radius: 5px !important;
    &:hover{
    background: linear-gradient(to right,rgba(71,118,230,1),rgba(142,84,233,1))!important;   
        color: ${({ theme }) => theme.colors.white}!important;        
    }
    &:focus{
    background: linear-gradient(to right,rgba(71,118,230,1),rgba(142,84,233,1))!important;
    //background-color: rgba(71,118,230,0.75)!important;
        color: ${({ theme }) => theme.colors.white}!important;        
    }
    &:active{
        background: linear-gradient(to right,rgba(71,118,230,1),rgba(142,84,233,1))!important;    
        color: ${({ theme }) => theme.colors.white}!important;       
    }

`;
