import styled, { keyframes } from 'styled-components';

const changeOpacity = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: .5;
  }
`;
const changeOpacityback = keyframes`
  from {
    opacity: .5;
  }

  to {
    opacity: 1;
  }
`;
export const StyledButton = styled.button`
  font-size: 1em;
  margin: 0em;
  margin-left: 1em;
  padding: 0.5em 1.5em;
  border-radius: 3px;
  background: ${props => props.theme.background};
  width: 100%;
  color: ${props => props.theme.color};
  animation: ${changeOpacityback} 1.1s ease-in;
  -webkit-animation: ${changeOpacityback} 1.1s ease-in;
  border: none;
  //border: 2px solid ${props => props.theme.color};
  &:hover {
    animation: ${changeOpacity} 1.1s ease-in;
    -webkit-animation: ${changeOpacity} 1.1s ease-in;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
`;

StyledButton.defaultProps = {
  theme: {
    color: 'white',
    background: 'paleviolet',
  },
};
